import React from "react";
import BtmNav from "../../BtmNav";
import Footer from "../../Footer";
import "../../placesknowmore/Kashi.css";
import NepalData from "./NepalData";
import NepalContent from "./NepalContent";
import NepalImagData from "./NepalImgData";
import MarqueeBottom from "../../MarqueeBottom";
import "../../../../node_modules/photoswipe/dist/photoswipe.css";
import "../../../../node_modules/photoswipe/dist/default-skin/default-skin.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const Nepal = () => {
  return (
    <>
      <section id="international">
        <div className="container">
          <div className="title">
            <h4 className="title-primary">Perfect Nepal Tour Package</h4>
            <h1 className="h1 h1-responsive title-secondary">Nepal Tour</h1>
          </div>

          <div className="row mt-5 px-0 py-5">
            <div className="col-md-6">
              <div className="accordion" id="accordionExample">
                {NepalData.map((curElem) => {
                  const { id } = curElem;
                  return <NepalContent key={id} {...curElem} />;
                })}
              </div>
            </div>

            <div className="col-md-6">
              <div class="places-img-container">
                <Gallery>
                  {NepalImagData.map((imgDat) => {
                    return (
                      <Item
                        original={`${
                          process.env.REACT_APP_CLOUDINARY_URL + imgDat.imgSrc
                        }`}
                        thumbnail={`${
                          process.env.REACT_APP_CLOUDINARY_URL + imgDat.imgSrc
                        }`}
                        width="1024"
                        height="768"
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            onClick={open}
                            src={`${
                              process.env.REACT_APP_CLOUDINARY_URL +
                              imgDat.imgSrc
                            }`}
                            alt={imgDat.imgAlt}
                          />
                        )}
                      </Item>
                    );
                  })}
                </Gallery>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MarqueeBottom />
      <Footer />
      <BtmNav />
    </>
  );
};
export default Nepal;
