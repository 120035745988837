import React from "react";

const AgraData = [
  {
    id: 1,
    question: "10 Days - 9 Night Package Tour Programme",
    answers: (
      <>
        Agar - Jaipur - Delhi - Kurukshetra - Amritsar - Haridwar - Rishikesh -
        Prayagraj - Kashi
        <br />
        <br />
        <b>1st Day -</b> Morning Agra Station Arrival & Local & Mathura to
        Jaipur- Night Journey Jaipur.
        <br />
        <b>2nd Day -</b> Jaipur Local & Journey to Delhi - Night Halt Delhi.
        <br />
        <b>3rd Day -</b> Delhi Local - Night Halt Delhi.
        <br />
        <b>4th Day -</b> Kurukshetra & Journey to Amritsar - Night Halt
        Amritsar.
        <br />
        <b>5th Day -</b> Golden Temple, Wagha Border Visit - Night Journey
        Haridwar.
        <br />
        <b>6th Day -</b> Haridwar Local-Night Halt Haridwar.
        <br />
        <b>7th Day -</b> Rishikesh Local & After Lunch Journey to Prayagraj -
        Night Journey.
        <br />
        <b>8th Day -</b> Prayagraj Local & Varanasi-Halt.
        <br />
        <b>8th Day -</b> Kashi Darshan & Evening Ganga Aarti & Boating-Halt
        Kashi.
        <br />
        <b>8th Day -</b> Varanasi Local & Evening Varanasi Station Drop.
      </>
    ),
  },
  {
    id: 2,
    question: "15 Days - 14 Night Package Tour Programme",
    answers: (
      <>
        Bhubaneswar- Puri - Konark - Kolkata - Gaya - Kashi - Prayagraj -
        Ayodhya - Haridwar - Rishikesh - Amritsar - Delhi - Mathura - Agra{" "}
        <br />
        <br />
        <b>1st Day -</b> Bhubaneswar Station Receiving & Local - Halt.
        <br />
        <b>2nd Day -</b> Puri & Konark Local & Night Journey Kolkata.
        <br />
        <b>3rd Day -</b> Kolkata Local & Night Journey Gaya.
        <br />
        <b>4th Day -</b> Gaya, Bodhgaya - Halt Varanasi.
        <br />
        <b>5th Day -</b> Kashi Temple Darshan & Evening Ganga Aarti &
        Boating-Halt Varanasi.
        <br />
        <b>6th Day -</b> 7:00AM to 1:00PM Varanasi Local Tour~ Halt Varanasi.
        <br />
        <b>7th Day -</b> Prayagraj Local - Halt Ayodhya.
        <br />
        <b>8th Day -</b> Ayodhya Local & Night Journey Haridwar.
        <br />
        <b>9th Day -</b> Morning Haridwar Local - Halt.
        <br />
        <b>10th Day -</b> Rishikesh Local & Night Journey Amritsar.
        <br />
        <b>11th Day -</b> Delhi Local - Halt.
        <br />
        <b>12th Day -</b> Morning Delhi to Mathura, Gokul Local - Halt.
        <br />
        <b>13th Day -</b> Agra Local & Night Drop to Agra Railway Station.
      </>
    ),
  },
];

export default AgraData;
