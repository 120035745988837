import React from "react";
import BtmNav from "../../BtmNav";
import Footer from "../../Footer";
import Card from "../../Card";
import { MiniCoachData } from "./MiniCoachData";
import "../cars.css";

const MiniCoach = () => {
  return (
    <>
      <section id="miniCoach">
        <div className="container">
          <div className="title">
            <h4 className="title-primary">Perfect Tempo Traveller for Tours</h4>
            <h1 className="h1 h1-responsive title-secondary">
              Featured Tempo Traveller
            </h1>
          </div>
          <div className="row mt-5 px-0 py-5">
            {MiniCoachData.map((cars) => {
              return <Card places={cars} key={cars.id} />;
            })}
          </div>
        </div>
      </section>
      <Footer />
      <BtmNav />
    </>
  );
};
export default MiniCoach;
