import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Swipercore, { Autoplay } from "swiper";

Swipercore.use([Autoplay]);

const Banner = () => {
  return (
    <section id="banner">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {/* <!-- Slides --> */}
          <div className="swiper-slide">
            <div className="caption">
              <div className="container">
                <h2>Relax & Enjoy</h2>
                <h1 className="h1 h1-responsive">It's Time to Adventure</h1>
                <a href="/#featured">
                  <i className="fas fa-play"></i> Take a Tour
                </a>
              </div>
            </div>
            <Swiper
              spaceBetween={15}
              slidesPerView={1}
              loop={true}
              autoplay={{ delay: 2500 }}
            >
              <SwiperSlide>
                <img
                  className="swiper-slide-b"
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/SliderImage1.jpg`}
                  alt="SliderImage1"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-slide-b"
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/SliderImage2.jpg`}
                  alt="SliderImage2"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-slide-b"
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/SliderImage3.jpg`}
                  alt="SliderImage3"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-slide-b"
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/SliderImage4.jpg`}
                  alt="SliderImage4"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-slide-b"
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/SliderImage5.jpg`}
                  alt="SliderImage5"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-slide-b"
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/SliderImage6.jpg`}
                  alt="SliderImage6"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-slide-b"
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/SliderImage7.jpg`}
                  alt="SliderImage7"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-slide-b"
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/SliderImage8.jpg`}
                  alt="SliderImage8"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-slide-b"
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/SliderImage9.jpg`}
                  alt="SliderImage9"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-slide-b"
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/SliderImage10.jpg`}
                  alt="SliderImage10"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
