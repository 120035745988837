import React from "react";

const KashiData = [
  {
    id: 1,
    question: "3 Days - 2 Night Package Tour Programme",
    answers: (
      <>
        Kashi Local Tour <br />
        <br />
        <b>1st Day -</b> Varanasi Arrival Ganga Aarti Boating - Halt.
        <br />
        <b>2nd Day -</b> Kashi Darshan - Halt.
        <br />
        <b>3rd Day -</b> Kashi Local - Drop.
      </>
    ),
  },
  {
    id: 2,
    question: "4 Days - 3 Night Package Tour Programme",
    answers: (
      <>
        Kashi - Ayodhya - Prayagraj <br />
        <br />
        <b>1st Day -</b> Kashi Darshan & Boating- Night Halt Kashi. <br />
        <b>2nd Day -</b> Morning Kashi Local - Halt. <br />
        <b>3rd Day -</b> Kashi to Ayodhya Visit - Halt. <br />
        <b>4th Day -</b> Ayodhya to Prayagraj Visit & Prayagraj Station Drop.
      </>
    ),
  },
  {
    id: 3,
    question: "4 Days - 3 Night Package Tour Programme",
    answers: (
      <>
        Kashi - Ayodhya - Prayagraj - Kashi
        <br />
        <br />
        <b>1st Day -</b> Kashi Darshan & Boating - Night Journey Ayodhya. <br />
        <b>2nd Day -</b> Ayodhya Local - Return Kashi Halt. <br />
        <b>3rd Day -</b> Kashi to Prayagraj Visit & Return Kashi - Halt Kas
        <br />
        <b>4th Day -</b> Kashi Local & Night Station Drop.
      </>
    ),
  },
  {
    id: 4,
    question: "5 Days - 4 Night Package Tour Programme",
    answers: (
      <>
        Kashi - Ayodhya - Prayagraj - Chitrakoot - Prayagraj <br />
        <br />
        <b>1st Day -</b> Kashi Darshan & Boating - Night Halt Kashi. <br />
        <b>2nd Day -</b> Morning Kashi Local - Night Journey Ayodhya.
        <br />
        <b>3rd Day -</b> Ayodhya Visit - Halt. <br />
        <b>4th Day -</b> Ayodhya to Prayagraj Visit & Chitrakoot - Halt.
        <br />
        <b>5th Day -</b> Chitrakoot Local & Night Prayagraj Station Drop.
      </>
    ),
  },
  {
    id: 5,
    question: "5 Days - 4 Night Package Tour Programme",
    answers: (
      <>
        Kashi - Gaya - Ayodhya - Prayagraj
        <br />
        <br />
        <b>1st Day -</b> Kashi Darshan & Boating- Night Halt Kashi.
        <br />
        <b>2nd Day -</b> Morning Kashi Local - Night Journey Gaya.
        <br />
        <b>3rd Day -</b> Gaya, Bodhgaya Visit Night Journey Ayodhya - Halt.
        <br />
        <b>4th Day -</b> Ayodhya Visit - Halt.
        <br />
        <b>5th Day -</b> Ayodhya to Prayagraj Local & Night Prayagraj Station
        Drop.
      </>
    ),
  },
  {
    id: 6,
    question: "5 Days - 4 Night Package Tour Programme",
    answers: (
      <>
        Kashi - Prayagraj - Ayodhya - Kashi
        <br />
        <br />
        <b>1st Day -</b> Varanasi Arrival Kashi Darshan & Boating - Night Halt
        Kashi.
        <br />
        <b>2nd Day -</b> Morning Kashi Local - Halt.
        <br />
        <b>3rd Day -</b> Kashi to Prayagraj Visit to Ayodhya - Halt.
        <br />
        <b>4th Day -</b> Ayodhya Visit to Kashi - Halt.
        <br />
        <b>5th Day -</b> Kashi Darshan & Varanasi Drop.
      </>
    ),
  },
  {
    id: 7,
    question: "6 Days - 5 Night Package Tour Programme",
    answers: (
      <>
        Kashi - Prayagraj - Ayodhya - Kashi - Gaya - Kashi <br />
        <br />
        <b>1st Day -</b> Varanasi St./Airport Arrival & Kashi Darshan & Evening
        Ganga Aarti, Boating-Halt.
        <br />
        <b>2nd Day -</b> Varanasi Local - Halt.
        <br />
        <b>3rd Day -</b> Morning Varanasi to Prayagraj Local to Ayodhya-Halt.
        <br />
        <b>4th Day -</b> Ayodhya Local to Varanasi-Halt.
        <br />
        <b>5th Day -</b> Early Morning Varanasi to Gaya, Bodhgaya Local & Return
        Varanasi-Halt.
        <br />
        <b>6th Day -</b> At Noon Varanasi St./Airport Departure.
      </>
    ),
  },
  {
    id: 8,
    question: "6 Days - 5 Night Package Tour Programme",
    answers: (
      <>
        Kashi - Prayagraj - Agra - Delhi <br />
        <br />
        <b>1st Day -</b> Morning Varanasi Arrival & Kashi Darshan & Ganga Aarti
        & Boating - Night Halt Varanasi. <br />
        <b>2nd Day -</b> Morning Varanasi Local Visit - Night Halt Kashi.
        <br />
        <b>3rd Day -</b> Early Morning Prayagraj Journey & Visit - Night Journey
        Agra. <br />
        <b>4th Day -</b> Agra - Mathura Visit- Night Halt Delhi. <br />
        <b>5th Day -</b> Delhi Visit - Night Halt Delhi.
        <br />
        <b>6th Day -</b> Delhi Visit & Evening Delhi Station Drop.
      </>
    ),
  },
  {
    id: 9,
    question: "7 Days - 6 Night Package Tour Programme",
    answers: (
      <>
        Kashi- Ayodhya - Prayagraj - Agra - Delhi <br />
        <br />
        <b>1st Day -</b> Morning Varanasi Arrival & Kashi Darshan & Ganga Aarti
        & Boating - Night Halt Varanasi.
        <br />
        <b>2nd Day -</b> Morning Varanasi Local Visit - Night Halt Kashi.
        <br />
        <b>3rd Day -</b> Early Morning Ayodhya Journey & Visit - Night Halt
        Ayodhya.
        <br />
        <b>4th Day -</b> Morning Prayagraj Journey & Visit - Night Journey Agra.
        <br />
        <b>5th Day -</b> Agra - Mathura Visit - Night Halt Delhi.
        <br />
        <b>6th Day -</b> Delhi Visit - Night Halt Delhi.
        <br />
        <b>7th Day -</b> Delhi Visit & Evening Delhi Station Drop.
      </>
    ),
  },
  {
    id: 10,
    question: "8 Days - 7 Night Package Tour Programme",
    answers: (
      <>
        Kashi - Gaya - Ayodhya - Prayagraj - Chitrakoot - Satna <br />
        <br />
        <b>1st Day -</b> Kashi Darshan & Boating - Night Halt Kashi.
        <br />
        <b>2nd Day -</b> Morning Kashi Local - Halt.
        <br />
        <b>3rd Day -</b> Early Morning Gaya Journey Bodhgaya Visit- Halt.
        <br />
        <b>4th Day -</b> Gaya Visit & Night Journey Ayodhya.
        <br />
        <b>5th Day -</b> Ayodhya Local - Halt.
        <br />
        <b>6th Day -</b> Ayodhya to Prayagraj Local & Chitrakoot - Halt.
        <br />
        <b>7th Day -</b> Chitrakoot Visit - Halt.
        <br />
        <b>8th Day -</b> Chitrakoot to Satna Station Drop.
      </>
    ),
  },
  {
    id: 11,
    question: "8 Days - 7 Night Package Tour Programme",
    answers: (
      <>
        Varanasi - BodhGaya - Rajgir - Nalanda - Kushinagar - Lumbini - Srawasti
        - Varanasi <br />
        <br />
        <b>1st Day -</b> Varanasi Arrival & Darshan, Ganga Aarti & Boating -
        Halt.
        <br />
        <b>2nd Day -</b> Visit to Halt Bodhgaya.
        <br />
        <b>3rd Day -</b> Morning Bodhgaya to Rajgir & Nalanda Visit - Night Halt
        Patna.
        <br />
        <b>4th Day -</b> Patna to Vaishali to Kushinagar - Halt Kushinagar.
        <br />
        <b>5th Day -</b> Kushinagar Local - Nepal Border - Halt Nepal Border.
        <br />
        <b>6th Day -</b> Lumbini - Kapilvastu - Srawasti - Halt Srawasti.
        <br />
        <b>7th Day -</b> Srawasti Visit to Varanasi - Halt.
        <br />
        <b>8th Day -</b> Varanasi Local Tour - Evening Drop.
      </>
    ),
  },
];

export default KashiData;
