import React from "react";

const NepalData = [
  {
    id: 1,
    question: "4 Days - 3 Night Package Tour Programme",
    answers: (
      <>
        Kathmandu to Kathmandu <br />
        <br />
        <b>1st Day -</b> Morning Kathmandu Tribhuvan International Airport
        Arrival & Drop to Hotel- Night Halt Kathmandu.
        <br />
        <b>2nd Day -</b> Kathmandu Local - Night Halt Kathmandu.
        <br />
        <b>3rd Day -</b> Kathmandu Local- Night Halt Kathmandu.
        <br />
        <b>4th Day -</b> Breakfast & Morning Check-out & Kathmandu Tribhuvan
        Airport Drop.
      </>
    ),
  },
  {
    id: 2,
    question: "5 Days - 4 Night Package Tour Programme",
    answers: (
      <>
        Varanasi - Gorakhpur - Kathmandu - Varanasi <br />
        <br />
        <b>1st Day -</b> Morning Varanasi Pick-up & Journey to Gorakhpur & Visit
        to Gorakhnath Temple Darshan -Night Halt Nepal Border. <br />
        <b>2nd Day -</b> Morning Breakfast & Journey to Kathmandu - Night Halt.{" "}
        <br />
        <b>3rd Day -</b> Kathmandu Local Tour - (Pashupatinath Temple,
        Bhugishwari Devi, Budha Nilkanth, Swambhunath, Doleswar Mahadev, Royal
        Palace) - Halt Kathmandu. <br />
        <b>4th Day -</b> Morning Kathmandu to Nepal Border Journey Via
        Manokamana Devi Temple Darshan-Night Halt Border. <br />
        <b>5th Day -</b> Morning Nepal Border to Varanasi - Night Varanasi Drop.
      </>
    ),
  },
];

export default NepalData;
