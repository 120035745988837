export const VehicleData = [
  {
    id: 1,
    placeName: "Cars",
    imgSrc:
      "https://res.cloudinary.com/saitravel/image/upload/CarCardImage.jpg",
    btnTxt: "Know More",
    btnLink: "/cars",
    imgAlt: "car",
  },
  {
    id: 2,
    placeName: "Urbania",
    imgSrc:
      "https://res.cloudinary.com/saitravel/image/upload/Urbania_zr5oec.jpg",
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "Urbania",
  },
  {
    id: 3,
    placeName: "Tempo Traveller",
    imgSrc: "https://res.cloudinary.com/saitravel/image/upload/TTCardImage.jpg",
    btnTxt: "Know More",
    btnLink: "/minicoach",
    imgAlt: "mini coach",
  },
  {
    id: 4,
    placeName: "Luxury Bus",
    imgSrc: "https://res.cloudinary.com/saitravel/image/upload/LBCardImage.jpg",
    btnTxt: "Know More",
    btnLink: "/luxurycoach",
    imgAlt: "luxury coach",
  },
];
