import React from "react";
const TestimonialsPage = () => {
  return (
    <section id="Testimonial">
      <div className="title">
        <h4 className="title-primary">What people say about us</h4>
        <h1 className="h1 h1-responsive title-secondary">Testimonials</h1>
      </div>
      {/* <div class="elfsight-app-4f6e0803-1ed1-45dc-b479-9c3ef4a1eb67"></div> */}
      <div
        class="elfsight-app-393e5a33-1c2c-4689-ba0d-838502733f87"
        data-elfsight-app-lazy
      ></div>
    </section>
  );
};
export default TestimonialsPage;
