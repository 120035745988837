export const LuxuryCoachData = [
  {
    id: 1,
    placeName: "Bharat Benz - 2x1 - 31 Seats",
    imgSrc: "https://res.cloudinary.com/saitravel/image/upload/LBImage1.jpg",
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "bus",
  },
  {
    id: 2,
    placeName: "MG Bus - 2x2 - 49 Seats",
    imgSrc: "https://res.cloudinary.com/saitravel/image/upload/LBImage2.jpg",
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "bus",
  },
  {
    id: 3,
    placeName: "Bus - 2x2 - 49 Seats",
    imgSrc: "https://res.cloudinary.com/saitravel/image/upload/LBImage3.jpg",
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "bus",
  },
  {
    id: 4,
    placeName: "Bus - 2x2 - 49 Seats",
    imgSrc: "https://res.cloudinary.com/saitravel/image/upload/LBImage4.jpg",
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "bus",
  },
  {
    id: 5,
    placeName: "Bus - 2x2 - 49 Seats",
    imgSrc: "https://res.cloudinary.com/saitravel/image/upload/LBImage5.jpg",
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "bus",
  },
  {
    id: 6,
    placeName: "Bus - 2x2 - 53 Seats",
    imgSrc: "https://res.cloudinary.com/saitravel/image/upload/LBImage6.jpg",
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "bus",
  },
];
