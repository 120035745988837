import React from "react";
import "./index.css";
import { Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Kashi from "./components/placesknowmore/kashi/Kashi";
import Prayagraj from "./components/placesknowmore/prayagraj/Prayagraj";
import Uttarakhand from "./components/placesknowmore/uttarakhand/Uttarakhand";
import Dham from "./components/placesknowmore/dham/Dham";
import Delhi from "./components/placesknowmore/delhi/Delhi";
import Agra from "./components/placesknowmore/agra/Agra";
import Cars from "./components/vehichleknowmore/cars/Cars";
import MiniCoach from "./components/vehichleknowmore/minicoach/MiniCoach";
import LuxuryCoach from "./components/vehichleknowmore/luxurycoach/LuxuryCoach";
import Nepal from "./components/internationalplaces/nepal/Nepal";
import IndianFood from "./components/foodknowmore/indian/IndianFood";
import SouthIndianFood from "./components/foodknowmore/southIndian/SouthIndianFood";
import MaharastrianFood from "./components/foodknowmore/maharastrian/MaharastrianFood";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";

import ReactGA from "react-ga";

const App = () => {
  const TRACKING_ID = "G-5JXB5T2D6G";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/kashi" component={Kashi} />
        <Route exact path="/prayagraj" component={Prayagraj} />
        <Route exact path="/uttarakhand" component={Uttarakhand} />
        <Route exact path="/dham" component={Dham} />
        <Route exact path="/delhi" component={Delhi} />
        <Route exact path="/agra" component={Agra} />
        <Route exact path="/cars" component={Cars} />
        <Route exact path="/minicoach" component={MiniCoach} />
        <Route exact path="/luxurycoach" component={LuxuryCoach} />
        <Route exact path="/nepal" component={Nepal} />
        <Route exact path="/indianfood" component={IndianFood} />
        <Route exact path="/southindianfood" component={SouthIndianFood} />
        <Route exact path="/maharastrianfood" component={MaharastrianFood} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/aboutus" component={AboutUs} />
      </Switch>
    </>
  );
};

export default App;
