const PrayagrajImagData = [
  {
    id: 1,
    imgSrc: "PrayagrajImg1.jpg",
    imgAlt: "img1",
  },
  {
    id: 2,
    imgSrc: "PrayagrajImg2.jpg",
    imgAlt: "img2",
  },
  {
    id: 3,
    imgSrc: "PrayagrajImg3.jpg",
    imgAlt: "img3",
  },
  {
    id: 4,
    imgSrc: "PrayagrajImg4.jpg",
    imgAlt: "img4",
  },
  {
    id: 5,
    imgSrc: "PrayagrajImg5.jpg",
    imgAlt: "img5",
  },
  {
    id: 6,
    imgSrc: "PrayagrajImg6.jpg",
    imgAlt: "img6",
  },
  {
    id: 7,
    imgSrc: "PrayagrajImg7.jpg",
    imgAlt: "img7",
  },
  {
    id: 8,
    imgSrc: "PrayagrajImg8.jpg",
    imgAlt: "img8",
  },
  {
    id: 9,
    imgSrc: "PrayagrajImg9.jpg",
    imgAlt: "img9",
  },
];

export default PrayagrajImagData;
