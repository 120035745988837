const KashiImagData = [
  {
    id: 1,
    imgSrc: "KashiImg1.jpg",
    imgAlt: "img1",
  },
  {
    id: 2,
    imgSrc: "KashiImg2.jpg",
    imgAlt: "img2",
  },
  {
    id: 3,
    imgSrc: "KashiImg3.jpg",
    imgAlt: "img3",
  },
  {
    id: 4,
    imgSrc: "KashiImg4.jpg",
    imgAlt: "img4",
  },
  {
    id: 5,
    imgSrc: "KashiImg5.jpg",
    imgAlt: "img5",
  },
  {
    id: 6,
    imgSrc: "KashiImg6.jpg",
    imgAlt: "img6",
  },
  {
    id: 7,
    imgSrc: "KashiImg7.jpg",
    imgAlt: "img7",
  },
  {
    id: 8,
    imgSrc: "KashiImg8.jpg",
    imgAlt: "img8",
  },
  {
    id: 9,
    imgSrc: "KashiImg9.jpg",
    imgAlt: "img9",
  },
];

export default KashiImagData;
