import chudamattar from '../../../Images/southIndianFood/chudaMattar.jpg';
import dalFry from '../../../Images/southIndianFood/dalFry.jpg';
import idli from '../../../Images/southIndianFood/idliChuttney.jpg';
import mixveg from '../../../Images/southIndianFood/mixVeg.jpg';
import mixVegSabji from '../../../Images/southIndianFood/mixVegSabji.jpg';
import namkeen from '../../../Images/southIndianFood/namkeen.jpg';
import pakodiSabudana from '../../../Images/southIndianFood/pakodiSabudana.jpg';
import pakodi from '../../../Images/southIndianFood/pakodi.jpg';
import pudi from '../../../Images/southIndianFood/pudi.jpg';
import roti from '../../../Images/southIndianFood/roti.jpg';
import sabji from '../../../Images/southIndianFood/sabji.jpg';
import uttpam from '../../../Images/southIndianFood/uttpam.jpg';
import dahiBara from '../../../Images/southIndianFood/dahiBara.jpg';
import gajjarHalwa from '../../../Images/southIndianFood/gajjarHalwa.jpg';
import jalebi from '../../../Images/southIndianFood/jalebi.jpg';
import jammunRassgulla from '../../../Images/southIndianFood/jammunRassgulla.jpg';
import jamun from '../../../Images/southIndianFood/jamun.jpg';
import laungLauta from '../../../Images/southIndianFood/laungLauta.jpg';
import malaiRoll from '../../../Images/southIndianFood/malaiRoll.jpg';
const SouthIndianFoodImagData = [
    {
        id:1,
        imgSrc:chudamattar,
        imgAlt:"chuda mattar"
    },
    {
        id:2,
        imgSrc:dalFry,
        imgAlt:"dal fry"
    },
    {
        id:3,
        imgSrc:idli,
        imgAlt:"idli"
    },
    {
        id:4,
        imgSrc:mixveg,
        imgAlt:"mix veg"
    },
    {
        id:5,
        imgSrc:mixVegSabji,
        imgAlt:"mix veg sabji"
    },
    {
        id:6,
        imgSrc:namkeen,
        imgAlt:"namekeen"
    },
    {
        id:7,
        imgSrc:pakodiSabudana,
        imgAlt:"pakoid sabudana"
    },
    {
        id:8,
        imgSrc:pakodi,
        imgAlt:"pakodi"
    },
    {
        id:9,
        imgSrc:pudi,
        imgAlt:"pudi"
    },
    {
        id:10,
        imgSrc:roti,
        imgAlt:"roti"
    },
    {
        id:11,
        imgSrc:sabji,
        imgAlt:"sabji"
    },
    {
        id:12,
        imgSrc:uttpam,
        imgAlt:"uttpam"
    },
    {
        id:13,
        imgSrc:dahiBara,
        imgAlt:"dahi bara"
    },
    {
        id:14,
        imgSrc:gajjarHalwa,
        imgAlt:"gajjar halwa"
    },
    {
        id:15,
        imgSrc:jalebi,
        imgAlt:"jalebi"
    },
    {
        id:16,
        imgSrc:jammunRassgulla,
        imgAlt:"jamun rasgulla"
    },
    {
        id:17,
        imgSrc:jamun,
        imgAlt:"jamin"
    },
    {
        id:18,
        imgSrc:malaiRoll,
        imgAlt:"malai roll"
    },
    {
        id:19,
        imgSrc:laungLauta,
        imgAlt:"laung lauta"
    },
]

export default SouthIndianFoodImagData;