import Traveller17Seats from "../../../Images/vehicleimg/Traveller17Seats.jpg";
import Traveller20Seats from "../../../Images/vehicleimg/Traveller20Seats.jpg";
import Traveller26Seats from "../../../Images/vehicleimg/Traveller26Seats.jpg";
import cruiser from "../../../Images/vehicleimg/cruiser.jpg";

export const MiniCoachData = [
  {
    id: 1,
    placeName: "Tempo Traveller - 17 Seats",
    imgSrc: Traveller17Seats,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "tempo traveller",
  },
  {
    id: 2,
    placeName: "Tempo Traveller - 20 Seats",
    imgSrc: Traveller20Seats,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "tempo traveller",
  },
  // {
  //   id: 3,
  //   placeName: "Tempo Traveller - 20 Seats",
  //   imgSrc: Traveller20Seats,
  //   btnTxt: "Book Now",
  //   btnLink: "/contact",
  //   imgAlt: "tempo traveller",
  // },
  {
    id: 4,
    placeName: "Tempo Traveller - 26 Seats",
    imgSrc: Traveller26Seats,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "tempo traveller",
  },
  {
    id: 5,
    placeName: "Cruiser - 13+D",
    imgSrc: cruiser,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "tempo traveller",
  },
];
