import innova from "../../../Images/vehicleimg/innova.JPEG";
import innovaCrysta from "../../../Images/vehicleimg/INNOVA CRYSTA.jpg";
import swift from "../../../Images/vehicleimg/SWIFT.jpg";
import ertiga from "../../../Images/vehicleimg/ERTIGA.jpg";

export const CarsData = [
  {
    id: 3,
    placeName: "Swift Dzire - 4+D",
    imgSrc: swift,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "swift",
  },
  {
    id: 6,
    placeName: "Ertiga - 6+D, 7+D",
    imgSrc: ertiga,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "ertiga",
  },
  {
    id: 1,
    placeName: "Innova - 6+D, 7+D",
    imgSrc: innova,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "innova",
  },
  {
    id: 2,
    placeName: "Innova Crysta - 6+D, 7+D",
    imgSrc: innovaCrysta,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "innova crysta",
  },
];
