const NepalImagData = [
  {
    id: 1,
    imgSrc: "/NepalImg1.jpg",
    imgAlt: "img1",
  },
  {
    id: 2,
    imgSrc: "/NepalImg2.jpg",
    imgAlt: "img2",
  },
  {
    id: 3,
    imgSrc: "/NepalImg3.jpg",
    imgAlt: "img3",
  },
  {
    id: 4,
    imgSrc: "/NepalImg4.jpg",
    imgAlt: "img4",
  },
  {
    id: 5,
    imgSrc: "/NepalImg5.jpg",
    imgAlt: "img5",
  },
  {
    id: 6,
    imgSrc: "/NepalImg6.jpg",
    imgAlt: "img6",
  },
  {
    id: 7,
    imgSrc: "/NepalImg7.jpg",
    imgAlt: "img7",
  },
  {
    id: 8,
    imgSrc: "/NepalImg8.jpg",
    imgAlt: "img8",
  },
  {
    id: 9,
    imgSrc: "/NepalImg9.jpg",
    imgAlt: "img9",
  },
];

export default NepalImagData;
