import React, { useState } from "react";
import emailjs from "emailjs-com";
import mapin from "../Images/map-pin.svg";
import phone from "../Images/phone.svg";
import email from "../Images/mail.svg";

const Result = () => {
  return (
    <p className="shadow-4 bg-light rounded p-3 mb-2 bg-gradient text-dark">
      We have received your message. We Will Contact You soon. Thankyou:)
    </p>
  );
};
const Contact = () => {
  const inputs = document.querySelectorAll(".input");

  function focusFunc() {
    let parent = this.parentNode;
    parent.classList.add("focus");
  }

  function blurFunc() {
    let parent = this.parentNode;
    if (this.value === "") {
      parent.classList.remove("focus");
    }
  }

  inputs.forEach((input) => {
    input.addEventListener("focus", focusFunc);
    input.addEventListener("blur", blurFunc);
  });
  const [result, showResult] = useState(false);
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_w6sutkb",
        "template_ucpe585",
        e.target,
        "user_mfoZ9U8HLQItD2FWfTrv0"
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
    e.target.reset();
    showResult(true);
  }
  const [inputUp, setInputUp] = useState(false);
  return (
    <>
      <div className="form-container">
        <span className="big-circle"></span>
        <div className="form">
          <div className="contact-info">
            <h3 className="title">Let's get in touch</h3>
            <p className="text">
              Leave a message and we will get back to you soon...
            </p>

            <div className="info">
              <div className="information">
                <img src={mapin} className="icon" alt="" />
                <p style={{ marginBottom: 0 }}>
                  D.35/91-A, Pyari Durga Complex (Near Hotel Vibhav Harsh)
                  Jangambari, Varanasi (Uttar Pradesh) - India
                </p>
              </div>
              <div className="information">
                <img src={email} className="icon" alt="" />
                <p style={{ marginBottom: 0 }}>himjais7@gmail.com</p>
              </div>
              <div className="information">
                <img src={phone} className="icon" alt="" />
                <p style={{ marginBottom: 0 }}>+91 7499999755</p>,
              </div>
              <div className="information">
                <img src={phone} className="icon" alt="" />
                <p style={{ marginBottom: 0 }}>+91 9919047413</p>
              </div>
              <div className="information">
                <img src={phone} className="icon" alt="" />
                <p style={{ marginBottom: 0 }}>+91 8115588957</p>
              </div>
            </div>

            <div className="social-media">
              <p>Connect with us :</p>
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/himjais74"
                  role="button"
                  aria-label="Facebook"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://instagram.com/saitravel_cateringservices?utm_medium=copy_link"
                  role="button"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://youtube.com/channel/UC70Iyl4ii489vUlbGvzSVqQ"
                  role="button"
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  href="https://wa.me/918115588957?text=Hello,%20I%20want%20to%20book%20a%20trip%20package%20with%20Sai%20Travel%20&%20Catering%20Services."
                  target="_blank"
                  role="button"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-whatsapp"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form
              action="./thankyou.html"
              method="post"
              autocomplete="off"
              netlify
              onSubmit={sendEmail}
            >
              <h3 className="title">Contact us</h3>
              <div
                className={
                  inputUp ? "input-container focus " : "input-container"
                }
              >
                <input
                  onClick={() => setInputUp(!inputUp)}
                  type="text"
                  name="name"
                  className="input"
                  required
                />
                <label for="">Full Name</label>
                <span>Full Name</span>
              </div>
              <div className="input-container">
                <input type="email" name="email" className="input" />
                <label for="">Email</label>
                <span>Email</span>
              </div>
              <div className="input-container">
                <input type="tel" name="phone" className="input" required />
                <label for="">Phone</label>
                <span>Phone</span>
              </div>
              <div className="input-container textarea">
                <textarea name="message" className="input"></textarea>
                <label for="">Message</label>
                <span>Message</span>
              </div>
              <button type="submit" className="contact-btn">
                Send
              </button>
              <div className="row mt-3 rounded">
                {result ? <Result /> : null}
              </div>
            </form>
          </div>
        </div>
        <span className="big-circle2"></span>
      </div>
    </>
  );
};
export default Contact;
