import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Swipercore, { Autoplay } from "swiper";

Swipercore.use([Autoplay]);

const ShowcaseSwiper = () => {
  return (
    <div className="swiper-container">
      <div className="swiper-wrapper">
        <Swiper
          spaceBetween={15}
          slidesPerView={3}
          loop={true}
          autoplay={{ delay: 2000 }}
        >
          <SwiperSlide>
            <img
              className="swiper-slide"
              src={`${process.env.REACT_APP_CLOUDINARY_URL}/showCase1.jpg`}
              alt="showCase1"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="swiper-slide"
              src={`${process.env.REACT_APP_CLOUDINARY_URL}/showCase2.jpg`}
              alt="showCase2"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="swiper-slide"
              src={`${process.env.REACT_APP_CLOUDINARY_URL}/showCase3.jpg`}
              alt="showCase3"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="swiper-slide"
              src={`${process.env.REACT_APP_CLOUDINARY_URL}/showCase4.jpg`}
              alt="showCase4"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="swiper-slide"
              src={`${process.env.REACT_APP_CLOUDINARY_URL}/showCase5.jpg`}
              alt="showCase5"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="swiper-slide"
              src={`${process.env.REACT_APP_CLOUDINARY_URL}/showCase6.jpg`}
              alt="showCase6"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="swiper-slide"
              src={`${process.env.REACT_APP_CLOUDINARY_URL}/showCase7.jpg`}
              alt="showCase7"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="swiper-slide"
              src={`${process.env.REACT_APP_CLOUDINARY_URL}/showCase8.jpg`}
              alt="showCase8"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="swiper-slide"
              src={`${process.env.REACT_APP_CLOUDINARY_URL}/showCase9.jpg`}
              alt="showCase9"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="swiper-slide"
              src={`${process.env.REACT_APP_CLOUDINARY_URL}/showCase10.jpg`}
              alt="showCase10"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default ShowcaseSwiper;
